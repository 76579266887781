import React from "react"

import minttu from "../images/minttupallo.svg"
import lime from "../images/limepallo.svg"
import oranssi from "../images/oranssipallo.svg"
import pinkki from "../images/pinkkipallo.svg"
import punanen from "../images/punanenpallo.svg"
import sininen from "../images/sininenpallo.svg"
import vihrea from "../images/vihreapallo.svg"
import violetti from "../images/violettipallo.svg"

import "./blobs.css"

const Blobs = ({ children, className }) => {

  return (
    <div className={className}>
      <img className="minttu" alt="" src={minttu}></img>
      <img className="oranssi" alt="" src={oranssi}></img>
      <img className="pinkki" alt="" src={pinkki}></img>
      <img className="punanen" alt="" src={punanen}></img>
      <img className="sininen" alt="" src={sininen}></img>
      <img className="vihrea" alt="" src={vihrea}></img>
      <img className="violetti" alt="" src={violetti}></img>
      <img className="lime" alt="" src={lime}></img>
    </div>
  )
}

export default Blobs
